import React from "react";
import Helmet from "react-helmet";

import Section from "$ui/section";
import Heading from "$ui/heading";

const PrivacyPolicyPage = () => (
  <Section className="pt-32">
    <Helmet>
      <title>TheCRO &mdash; Privacy Policy</title>
    </Helmet>

    <Heading>Privacy Policy</Heading>

    <article className="mt-10 space-y-10 leading-relaxed">
      <p>
        At CRO, we are committed to protecting your personal data and respecting
        your privacy. Please read the following terms to understand this better
        :-
      </p>

      <p>
        We use socially reasonable efforts to ensure that the collection of
        Personal Information is limited to that which is necessary to fulfill
        the purposes identified below. If we use or plan to use your information
        in a manner different than the purpose for which it is collected, then
        we will ask you for your consent prior to such use.
      </p>

      <p>
        The Personal Information collected will be used only for the purpose of
        the organization betterment enabling you to use the information provided
        by us, to help promote a consumer awareness, calibrate consumer interest
        in our policy and work, inform you about activities and prog, We can use
        and publish this information to prepare reports for consumer behavior,
        policy drafting from time to time
      </p>
    </article>
  </Section>
);

export default PrivacyPolicyPage;
